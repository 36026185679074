import React from 'react'
import AppRedirect from '../components/StoreRedirect'

const Download = () => {
  return (
    <div>
      <AppRedirect />
    </div>
  )
}

export default Download
