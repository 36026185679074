import React, { useEffect } from 'react'
import links from '../config/links'

const StoreRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = links.appStore
    } else if (/android/i.test(userAgent)) {
      window.location.href = links.googlePlay
    } else {
      // For other devices or desktops, you can redirect to your website or provide links to both app stores.
      window.location.href = 'https://urbanfox.network/app'
    }
  }, [])

  return <div>Redirecting...</div>
}

export default StoreRedirect
